import {
    Col,
    Container,
    Image,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';

import * as logo from './css/logo.png';

export const Homepage = ({ }) => {
    return (<>
        <Container>
            <Row>
                <Col className="col-md-9">
                    <Image src={ logo } />
                </Col>
                <Col className="pt-3">
                    <h5>1-905-660-9740</h5>
                </Col>
            </Row>
        </Container>

        <Navbar expand="lg" className="top-menu">
            <Container>
                <Navbar.Brand href="#home">Instant quote</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


        <Container fluid>
            <Row className="mt-4 mb-4">
                <Col>
                    <iframe style={ { width: "100%", minHeight: "800px" } } src="https://qat-oraclerms.link-nonprod.wawanesalife.com"></iframe>
                </Col>
            </Row>
        </Container>

        <Container fluid>
            <Row className="bg-black text-white">
                <Col className="text-center">
                    Insurance products | Sitemap
                </Col>
            </Row>
        </Container>

    </>);
}
